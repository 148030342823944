@import "../../../../../resources/styles/colors";

.taskSummaryTopLevel {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.taskSummaryChildren {
  margin-left: 1rem;
}

.taskSummaryLine{
  display: flex;
  margin: 0.5rem 0;
  color: @primaryText
}

.taskSummaryLineText {
  cursor: pointer;
}

.summaryIcon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem!important; // Anything less than 1rem and the rotation animation will distort (on fa-spinner icons).
  text-align: center;
}

.artifactsBlock {
  margin-left: 1.25rem;
}

.highlightBlock {
  color: @infoText;
  margin-left: 1.25rem;
}

.fatalBlock {
  color: @dangerText;
  margin-left: 1.25rem;
}
