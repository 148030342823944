@import "../../resources/styles/colors";
@import "../../resources/styles/variables";
@import "../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.textContainer {
    position: relative
}

.iconContainer {
    position: absolute;
    right: 0;
    top: @componentIconTopMargin;
    cursor: pointer;
}

.error {
    font-Size: 12/16rem;
    line-Height: 12/16rem;
    color: @dangerText;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.label {
    line-height: 22px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    user-select: none;
    color: @focus;
    font-size: 12/16rem;
    font-weight: @normal;
}

.visuallyHidden {
    .visuallyHiddenButAccessible();
}