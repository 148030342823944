@import "../../resources/styles/colors";
@import "../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.clickableChip {
    // We target the first child of this InternalLink, the Chip.
    > div:first-child:after {
        content: '';
        position: absolute;
        width: 100%;
        height:100%;
        top:0;
        left:0;
        background: @chipBackground;
        opacity: 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        border-radius: 1rem;
        z-index: 0
    }
    > div:first-child:hover:after {
        opacity: 0.2;
    }
    > div span {
        z-index: 1;
    }
}

.chip {
    opacity: 1;
}

.visuallyHidden {
    .visuallyHiddenButAccessible();
}