@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.cardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    margin: 1rem 0 1.6rem 0;
}

.dashboardFilterInputClassName {
    font-size: 0.8125rem !important;
    width: 15rem !important;
    .searchIcon {
        position: absolute;
        right: 0;
        top: 12px;
        width: 20px !important;
        height: 20px !important;
    }
}

.card {
    margin: 1rem;
    width: 17.13rem;
    height: 21.875rem;
    min-height: 21.875rem;
    box-shadow: @boxshadow2;
    border-radius: 0.25rem;
    overflow: hidden;
    color: @primaryText;
    text-decoration: none;
    background: @paper2;
    transition: background-color 0.1s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    position: relative;
    .cardTitleContainer {
        color: @whiteConstant;
        background-color: @mainBackground;
        font-size: 1.063rem;
        height: 3rem;
        min-height: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        .cardCount {
            font-size: 0.875rem;
            font-weight: @light;
        }
    }
    .cardContentContainer {
        color: @secondaryText;
        font-size: 0.8125rem;
        padding: 1rem;
        overflow-y: auto;
    }
}
.card:after {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 281px;
    content: "";
    background: linear-gradient(to top, @paper2, transparent);
    pointer-events: none;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.environmentRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    .environmentIcon {
        display: flex;
        min-width: 1.5rem;
        padding-right: 0.4rem;
    }
    .environmentIcon {
        svg {
            width: 18px !important;
            height: 16px !important;
        }
    }
    .environmentName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .environmentMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.workerpoolRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    .workerpoolIcon {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .workerpoolIcon {
        svg {
            width: 18px !important;
            height: 16px !important;
        }
    }
    .workerpoolName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .workerpoolMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.endpointRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0.4rem;
    .endpointIcon {
        display: flex;
        min-width: 1rem;
        margin-right: 0.4rem;
        background-color: @whiteConstant;
        border-radius: 1.875rem;
        padding: 0.25rem;
    }
    .endpointName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .endpointMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.healthStatusRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0.4rem;
    .healthStatusIcon {
        background: @logoBackground;
        border-radius: 30px;
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
        border: 0.0625rem solid @logoBackground;
        img {
            display: flex;
            min-width: 1rem;
        }
    }
    .healthStatusName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .healthStatusMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.tenantRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0;
    .tenantName {
        padding-right: 0.4rem;
    }
    .tenantMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.tenantTagRowsOuterContainer {
    margin-bottom: 1rem;
    h5 {
        color: @primaryText;
        margin: 0.25rem 0;
        padding: 0;
    }
}
.tenantTagRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0;
    .tenantTagName {
        padding-right: 0.4rem;
    }
    .tenantTagMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}
