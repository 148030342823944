@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.select {
  width: 100% !important;
  min-width: 0 !important;
  overflow: visible; // Needed to display the floating labels/error messages associated with controls.
  display: flex;
  flex-direction: column;
}

.isDisabled * {
  color: @disabledButtonText !important;
  fill: @disabledButtonText;
}

.filter {
  margin: 0 1rem;
}

.container {
  width: 100%;
}

.empty {
  padding: 10/16rem;
}

.visuallyHidden {
  .visuallyHiddenButAccessible();
}